import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Container } from "react-bootstrap";
import White from "../../../../../Assets/White.png";
import { titleStyle } from "../../../../../components/Register/Talento/talentoStyles";
import './AdmStyles.css';
import BackBlue from '../../../../../Assets/BackBlue.svg';
import { backButtonStyle, backButtonIconStyle } from '../../../Talento/steps/ProfilePersStyles';
import StepperComponent from "../../../../Shared/StepperComponent/StepperComponent";
import ButtonComponent from "../../../../Shared/ButtonComponent";
import InfoProject from "./StepsInSteps1/InfoProject";
import ImagesProject from "./StepsInSteps1/ImagesProject";
import UsedToolsProject from "./StepsInSteps1/UsedToolsProject";
import { useTalentProjectsApi } from "../../../../../api/TalentProjectsApi";
import { UserContext } from "../../../../../contexts/UserContext";
import { toast } from "react-toastify";
import { formatISOToDate } from "../../../../../functions/calculateDate";
import { useHistory } from "react-router-dom";
import { useToolsApi } from "../../../../../api/ToolsApi";


export const endPointInterface = {
    talent_id: 0,
    name: "string",
    customer: "string",
    work_area_id: 22,
    role: "string",
    duration: "string",
    start_date: "2024-04-17",
    end_date: "2024-04-17",
    images: [
        "string"
    ],
    links: [
        "string"
    ],
    tools: [
        0
    ]
}



const AdmProjectsStep1 = ({ handleBackStep, project, isEdit }) => {
    const [step, setStep] = useState(-1);
    const [dataInfoProject, setDataInfoProject] = useState(null);
    const [dataImageProject, setDataImageProject] = useState(null);
    const [dataUsedTools, setDataUsedTools] = useState(null);
    const { createTalentProject, updateTalentProject } = useTalentProjectsApi();
    const { fetchTool } = useToolsApi();
    const { userData } = useContext(UserContext);
    const history = useHistory();
    const [toolNames, setToolNames] = useState('');



    useEffect(() => {
        console.log('datosReales', {
            images: dataImageProject?.images,
            links: dataImageProject?.links,
            name: dataInfoProject?.name,
            customer: dataInfoProject?.customer,
            work_area_id: dataInfoProject?.work_area_id,
            role: dataInfoProject?.role,
            duration: dataInfoProject?.duration,
            start_date: dataInfoProject?.start_date,
            end_date: dataInfoProject?.end_date,
            tools: dataUsedTools
        });
    }, [dataInfoProject, dataImageProject, dataUsedTools]);

    useEffect(() => {
        console.log('dataInfoProject', project);
    }, [project]);

    const handleEditProject = () => {
        updateTalentProject(project.id, {
            images: dataImageProject?.images,
            links: dataImageProject?.links,
            name: dataInfoProject?.name,
            customer: dataInfoProject?.customer,
            work_area_id: dataInfoProject?.work_area_id,
            role: dataInfoProject?.role,
            duration: dataInfoProject?.duration,
            start_date: dataInfoProject?.start_date,
            end_date: dataInfoProject?.end_date,
            tools: dataUsedTools
        }).then(() => {
            window.location.reload();
            history.push(`/profile/project/${project.id}/${userData.talent.id}`);
            toast.success('Proyecto actualizado con éxito');
        }).catch((error) => {
            toast.error(`Error al actualizar el proyecto ${error}`);
        });
    };

    const handleBackStepAdm = () => {
        setStep(step - 1);
    };

    const nextStep = () => {
        setStep(-1);
    };

    const goProfile = () => {
        const projectData = {
            talent_id: userData.talent.id,
            images: dataImageProject?.images || [],
            links: dataImageProject?.links || [],
            name: dataInfoProject?.name,
            customer: dataInfoProject?.customer,
            work_area_id: dataInfoProject?.work_area_id,
            role: dataInfoProject?.role,
            duration: dataInfoProject?.duration,
            start_date: formatISOToDate(dataInfoProject?.start_date),
            end_date: formatISOToDate(dataInfoProject?.end_date),
            tools: dataUsedTools
        };

        createTalentProject(projectData).then(() => {
            toast.success('Proyecto creado con éxito');
            history.push('/profile');
        }).catch((error) => {
            toast.error(`Error al crear el proyecto ${error}`);
        });

    };

    let currentStep = 0;
    if (dataInfoProject) {
        currentStep = 1;
    }
    if (dataImageProject) {
        currentStep = 2;
    }
    if (dataUsedTools) {
        currentStep = 3;
    }

    function formatProjectData(projectData) {
        if (!projectData || typeof projectData !== 'object') {
          console.error("Expected an object, but got:", typeof projectData, projectData);
          return "";
        }
      
        const startDate = new Date(projectData.start_date).toLocaleDateString('es-ES', {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit'
        });
      
        const endDate = projectData.end_date
          ? new Date(projectData.end_date).toLocaleDateString('es-ES', {
              day: '2-digit',
              month: '2-digit',
              year: '2-digit'
            })
          : 'En progreso';
      
        let result = [
          projectData.name,
          projectData.customer,
          projectData.role,
          projectData.duration,
          `${startDate} a ${endDate}`
        ]
        .filter(item => item) 
        .join(', ');
      
        return result;
      }    

      const translateToolIdsToNames = async (toolIds) => {
        if (!Array.isArray(toolIds) || toolIds.length === 0) {
            console.error('Tool IDs should be a non-empty array.');
            return '';
        }
    
        try {
            const tools = await Promise.all(toolIds.map(toolId => fetchTool(toolId)));
    
            const toolNames = tools
                .filter(tool => tool && tool.name_es)
                .map(tool => tool.name_es);
    
            return toolNames.join(', ');
        } catch (error) {
            console.error('Error translating tool IDs to names:', error);
            return '';
        }
    };

    const fetchAndSetToolNames = async () => {
        if (!Array.isArray(dataUsedTools) || dataUsedTools.length === 0) {
            return;
        }

        const names = await translateToolIdsToNames(dataUsedTools);
        setToolNames(names); 
    };

    useEffect(() => {
        fetchAndSetToolNames();
        console.log('dataUsedTools', toolNames);
    }, [fetchAndSetToolNames, toolNames]);
    
    const renderDefault = () => (
        <Container fluid className="d-flex flex-column justify-content-between h-100 portfolio-container">
            <button style={backButtonStyle} onClick={handleBackStep}>
                <img src={BackBlue} alt="back" style={backButtonIconStyle} />
            </button>
            <Row className="justify-content-center align-items-center">
                <Col className="text-center">
                    <img src={White} alt="Logo" className="logo-img" />
                    <h1 style={titleStyle}>Carga tu portfolio</h1>
                    <span className="portfolio-description">
                        Puedes cargar hasta 4 proyectos, muestra
                        tus mejores trabajos para conseguir
                        mejores resultados
                    </span>
                </Col>
            </Row>
            <div className="portfolio-footer">
                <StepperComponent currentStep={currentStep} steps={3} title={'Cargar proyecto'} subtitle={'Estado de tu proyecto'} />
                <Row className="w-100">
                    {[
                        { data: dataInfoProject, text: formatProjectData(dataInfoProject) ||'Información del proyecto' },
                        { data: dataImageProject, text: 'Imágenes' },
                        { data: dataUsedTools, text: toolNames ||'Herramientas usadas' }
                    ].map((item, index) => (
                        <Col key={index} xs={12} className="gap-after p-0">
                            <ButtonComponent
                                text={item.text}
                                large
                                version={item.data ? 'outline' : 'grey'}
                                isLeft
                                onClick={() => setStep(index)}
                                isEditIcon={item.data ? true : false}
                            />
                        </Col>
                    ))}
                    <Col xs={12} className="gap-after p-0">
                        <ButtonComponent text="Continuar" large version="grey" isHover onClick={
                            dataInfoProject && dataImageProject && dataUsedTools && isEdit ? handleEditProject : goProfile
                        } />
                    </Col>
                </Row>
            </div>
        </Container>
    );

    return (
        <>
            {step === -1 && renderDefault()}
            {step === 0 && <InfoProject handleBackStep={handleBackStepAdm} dataInfoProject={setDataInfoProject} />}
            {step === 1 && <ImagesProject handleBackStep={handleBackStepAdm} dataTools={setDataImageProject} nextStep={nextStep} />}
            {step === 2 && <UsedToolsProject handleBackStep={handleBackStepAdm} dataUsedTools={setDataUsedTools} nextStep={nextStep} />}
        </>
    );
};

export default AdmProjectsStep1;
