import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import White from '../../Assets/White.png';
import Back from '../../Assets/Back.svg';
import ButtonComponent from '../Shared/ButtonComponent';
import Indeed from '../../Assets/Indeed.svg';
import Linkedin from '../../Assets/Linkedin.svg';
import InputComponent from '../Shared/InputComponent';
import { useHistory } from 'react-router-dom';
import * as AuthApi from '../../api/AuthApi';
import styles from './LoginScreen.module.css';
import { UserContext } from '../../contexts/UserContext';

const messages = ['Bienvenido', 'Welcome', 'Bienvenue', 'Willkommen'];

const LoginScreen = () => {
  const [messageIndex, setMessageIndex] = useState(0);
  const [fade, setFade] = useState(true);
  const history = useHistory();
  const goBack = useCallback(() => window.history.back(), []);
  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const { updateUserData } = useContext(UserContext);

  const [emailTrimAndPass, setEmailTrimAndPass] = useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setFade(false);
      setTimeout(() => {
        setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
        setFade(true);
      }, 500);
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, [messageIndex]);

  const handleLogin = async () => {
    const emailValidation = await emailInputRef.current.validateExternal();
    const passwordValidation =
      await passwordInputRef.current.validateExternal();

    if (emailValidation.isValid && passwordValidation.isValid) {
      const email = emailValidation.value;
      const password = passwordValidation.value;
      const { success, data, message } = await AuthApi.login(email, password);

      if (success) {
        localStorage.setItem('userToken', data.token);
        localStorage.setItem(
          'userData',
          JSON.stringify({
            user: data.user,
            talent: data.talent,
            company: data.company,
          })
        );
        updateUserData({
          user: data.user,
          token: data.token,
          talent: data.talent,
          company: data.company,
        });
        if (data.user.email === 'x345cd@gmail.com') {
          history.push('/admin');
        } else if (data.user.role === 'COMPANY' && data.company) {
          history.push('/company-profile');
        } else {
          history.push('/profile');
        }
      } else {
        const errorMessage = message || 'Email o contraseña incorrectas';
        emailInputRef.current.setErrorFromOutside(errorMessage);
        passwordInputRef.current.setErrorFromOutside(errorMessage);
      }
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (token) {
      const userData = JSON.parse(localStorage.getItem('userData'));
      updateUserData({
        user: userData.user,
        token: token,
        talent: userData.talent,
      });
      history.push('/profile');
    }
  }, [history, updateUserData]);

  return (
    <Container fluid className={styles.loginContainer}>
      <button className={styles.goBackButton} onClick={goBack}>
        <img src={Back} alt="back" className={styles.goBackImage} />
      </button>
      <Row className="justify-content-center align-items-center">
        <Col className="text-center">
          <img src={White} alt="Logo" className={styles.logo} />
          <h1
            className={`${styles.messageHeader} ${
              fade ? styles.fadeIn : styles.fadeOut
            }`}
          >
            {messages[messageIndex]}
          </h1>
        </Col>
      </Row>
      <span className={styles.opportunityText}>
        ¿Listo para explorar nuevas oportunidades?
      </span>
      <div className={styles.loginFooter}>
        <form
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
          onSubmit={(e) => e.preventDefault()}
        >
          <InputComponent
            ref={emailInputRef}
            type="email"
            placeholder="Correo electrónico"
            onChange={(e) =>
              setEmailTrimAndPass({
                ...emailTrimAndPass,
                email: e.target.value,
              })
            }
          />
          <InputComponent
            ref={passwordInputRef}
            type="password"
            placeholder="Contraseña"
            onChange={(e) =>
              setEmailTrimAndPass({
                ...emailTrimAndPass,
                password: e.target.value,
              })
            }
          />
        </form>
        <ButtonComponent
          text="Ingresar"
          version={
            emailTrimAndPass.email.length > 0 &&
            emailTrimAndPass.password.length > 0
              ? 'fill'
              : 'grey'
          }
          large
          onClick={handleLogin}
          isHover
          disabled={
            emailTrimAndPass.email.length === 0 ||
            emailTrimAndPass.password.length === 0
          }
        />
        <div className={styles.dividerContainer}>
          <div className={styles.horizontalLine}></div>
          <span className={styles.orText}>O</span>
          <div className={styles.horizontalLine}></div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            width: '100%',
            height: '100px',
          }}
        >
          <ButtonComponent
            text="Ingresar con Linkedin"
            large
            version="grey"
            isBoxShadow
            isFontBlue
            icon={Linkedin}
            isHover
          />
          <ButtonComponent
            text="Ingresar con Indeed"
            large
            version="grey"
            isBoxShadow
            isFontBlue
            icon={Indeed}
            isHover
          />
        </div>
        <ButtonComponent
          text="Registrar"
          href={'/register'}
          version="outline"
          isFontBold
          paddingX={'4.5rem'}
          isHover
        />
        <a className={styles.authLink} href="/forgot-password">
          Olvidé mi contraseña.
        </a>
      </div>
    </Container>
  );
};

export default LoginScreen;
