import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  fullScreenStyles,
  logoStyle,
  buttonStyle,
  linkStyle,
} from './ProfilePersStyles';
import StepperComponent from '../../../Shared/StepperComponent/StepperComponent';
import BlueLogo from '../../../../Assets/BlueLogo.svg';
import { Container, Row, Col } from 'react-bootstrap';
import SliderComponent from '../../../Shared/SliderComponent/SliderComponent';
import { useCulturalQuizApi } from '../../../../api/QuizApi';
import ProfilePersSixRenderer from './ProfileSixRenderer';
import BackBlue from '../../../../Assets/BackBlue.svg';
import { useTalentQuizApi } from '../../../../api/TalentQuizApi';
import { UserContext } from '../../../../contexts/UserContext';

const ProfilePersSix = ({ gotostep, corporateCulture, back }) => {
  const [currentStage, setCurrentStage] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [quizData, setQuizData] = useState(null);
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const { userData } = useContext(UserContext);

  const { setTalentCulturalQuizAnswers } = useTalentQuizApi();
  const { fetchFullQuiz } = useCulturalQuizApi();
  const responseData =  {
    "stages": 
      [
        {
          "id": 1,
          "order": 1,
          "name_es": "En un entorno laboral",
          "questions": [
            {
              "id": 1,
              "order": 1,
              "type": "MULTIPLE_CHOICE",
              "amount": 3,
              "desc_es": "Selecciona tres valores que te definen",
              "options": [
                {
                  "id": 1,
                  "question_id": 1,
                  "name_es": "Responsabilidad",
                  "desc_es": "Cumplir tareas y compromisos, asumiendo las consecuencias propias."
                },
                {
                  "id": 2,
                  "question_id": 1,
                  "name_es": "Integridad",
                  "desc_es": "Honestidad, transparencia y ética."
                },
                {
                  "id": 3,
                  "question_id": 1,
                  "name_es": "Colaboración",
                  "desc_es": "Trabajo en equipo, comunicación y respeto."
                },
                {
                  "id": 4,
                  "question_id": 1,
                  "name_es": "Innovación",
                  "desc_es": "Buscar ideas y soluciones nuevas."
                },
                {
                  "id": 5,
                  "question_id": 1,
                  "name_es": "Orientación al cliente",
                  "desc_es": "Enfocarse en las necesidades del cliente."
                },
                {
                  "id": 6,
                  "question_id": 1,
                  "name_es": "Diversidad e inclusión",
                  "desc_es": "Valorar la diversidad de pensamientos, culturas y experiencias, y promover la inclusión en todas las áreas de la empresa."
                }
              ]
            },
            {
              "id": 5,
              "order": 1,
              "type": "ORDER",
              "amount": null,
              "desc_es": "¿Qué crees que es más importante en la resolución de conflictos?",
              "options": [
                {
                  "id": 21,
                  "question_id": 5,
                  "name_es": null,
                  "desc_es": "Ganar la discusión y demostrar que tengo razón."
                },
                {
                  "id": 22,
                  "question_id": 5,
                  "name_es": null,
                  "desc_es": "Mantener la armonía y encontrar una solución beneficiosa para ambas partes."
                },
                {
                  "id": 23,
                  "question_id": 5,
                  "name_es": null,
                  "desc_es": "Aprender de la situación para evitar futuros conflictos."
                }
              ]
            }
          ]
        },
        {
          "id": 2,
          "order": 2,
          "name_es": "Resolución de conflictos",
          "questions": [
            {
              "id": 2,
              "order": 2,
              "type": "SINGLE_SELECTION",
              "amount": null,
              "desc_es": "¿Qué es lo más importante para ti?",
              "options": [
                {
                  "id": 7,
                  "question_id": 2,
                  "name_es": null,
                  "desc_es": "La competitividad y el logro de objetivos."
                },
                {
                  "id": 8,
                  "question_id": 2,
                  "name_es": null,
                  "desc_es": "El respeto y la colaboración entre compañeros."
                },
                {
                  "id": 9,
                  "question_id": 2,
                  "name_es": null,
                  "desc_es": "La innovación y la creatividad en las soluciones."
                },
                {
                  "id": 10,
                  "question_id": 2,
                  "name_es": null,
                  "desc_es": "El crecimiento profesional y las oportunidades de desarrollo."
                },
                {
                  "id": 11,
                  "question_id": 2,
                  "name_es": null,
                  "desc_es": "El ambiente de trabajo positivo y motivador."
                },
                {
                  "id": 12,
                  "question_id": 2,
                  "name_es": null,
                  "desc_es": "La comunicación abierta y transparente."
                }
              ]
            },
            {
              "id": 6,
              "order": 2,
              "type": "ORDER",
              "amount": null,
              "desc_es": "¿Cómo reaccionarías si te enfrentas a una crítica constructiva sobre tu trabajo?",
              "options": [
                {
                  "id": 24,
                  "question_id": 6,
                  "name_es": null,
                  "desc_es": "Me sentiría ofendido y justificaría mi trabajo."
                },
                {
                  "id": 25,
                  "question_id": 6,
                  "name_es": null,
                  "desc_es": "Escucharía atentamente y trataría de mejorar en base a la crítica."
                },
                {
                  "id": 26,
                  "question_id": 6,
                  "name_es": null,
                  "desc_es": "Agradecería la retroalimentación y buscaría oportunidades para crecer profesionalmente."
                }
              ]
            }
          ]
        },
        {
          "id": 3,
          "order": 3,
          "name_es": "Motivación y trabajo en equipo",
          "questions": [
            {
              "id": 3,
              "order": 3,
              "type": "SINGLE_SELECTION",
              "amount": null,
              "desc_es": "¿Qué significa para ti?",
              "options": [
                {
                  "id": 13,
                  "question_id": 3,
                  "name_es": null,
                  "desc_es": "Cumplir con mis tareas asignadas sin importar las circunstancias."
                },
                {
                  "id": 14,
                  "question_id": 3,
                  "name_es": null,
                  "desc_es": "Ser proactivo en la resolución de problemas y asumir las consecuencias de mis acciones."
                },
                {
                  "id": 15,
                  "question_id": 3,
                  "name_es": null,
                  "desc_es": "Trabajar en equipo para lograr los objetivos de la empre."
                },
                {
                  "id": 16,
                  "question_id": 3,
                  "name_es": null,
                  "desc_es": "Tomar la iniciativa y anticiparme a las necesidades del trabajo para garantizar un buen desempeño."
                },
                {
                  "id": 17,
                  "question_id": 3,
                  "name_es": null,
                  "desc_es": "Asumir la responsabilidad de mis errores y aprender de ellos para mejorar en el futuro."
                }
              ]
            }
          ]
        },
        {
          "id": 4,
          "order": 4,
          "name_es": "Comunicación y liderazgo",
          "questions": [
            {
              "id": 4,
              "order": 4,
              "type": "ORDER",
              "amount": null,
              "desc_es": "Situación: Mi compañero no entrega a tiempo su material, lo que impide que yo pueda avanzar con  mi entrega, ya que mi trabajo depende del suyo.",
              "options": [
                {
                  "id": 18,
                  "question_id": 4,
                  "name_es": null,
                  "desc_es": "Dialogar con mi compañero para entender y, si es posible, ayudarlo en su tarea."
                },
                {
                  "id": 19,
                  "question_id": 4,
                  "name_es": null,
                  "desc_es": "Encontrar medidas temporales para seguir adelante con mi trabajo."
                },
                {
                  "id": 20,
                  "question_id": 4,
                  "name_es": null,
                  "desc_es": "Convocar al equipo para un diálogo en busca de soluciones comunes."
                }
              ]
            }
          ]
        }
      ]
  }
  useEffect(() => {
    const loadData = async () => {
      const data = await fetchFullQuiz();
      if (data) {
        setQuizData(responseData);
      }
    };
    loadData();
  }, []);

  const handleGoToStep = () => {
    gotostep(16);
    corporateCulture(selectedAnswers);
    console.log(selectedAnswers);
  };

  const handleNextQuestion = useCallback(async () => {
    const stage = quizData?.stages[currentStage];
    if (currentQuestion < stage?.questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else if (currentStage < quizData?.stages.length - 1) {
      setCurrentStage(currentStage + 1);
      setCurrentQuestion(0);
    } else {
      await setTalentCulturalQuizAnswers(
        userData.talent.id,
        Object.entries(selectedAnswers).map(([questionId, answerIds]) => ({
          question_id: parseInt(questionId),
          answer: answerIds,
        }))
      );
      handleGoToStep();
    }
  }, [
    currentQuestion,
    currentStage,
    quizData,
    selectedAnswers,
    setTalentCulturalQuizAnswers,
    userData.talent.id,
    handleGoToStep,
  ]);

  const handlePreviousQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    } else if (currentStage > 0) {
      const previousStage = currentStage - 1;
      setCurrentStage(previousStage);
      const previousStageQuestionCount =
        quizData?.stages[previousStage]?.questions.length || 0;
      setCurrentQuestion(previousStageQuestionCount - 1);
    }
  };

  const handleSelectAnswer = (questionId, answerId, type) => {
    setSelectedAnswers((prevSelected) => {
      const prevSelection = prevSelected[questionId] || [];
      if (type === 'MULTIPLE_CHOICE') {
        const newSelection = prevSelection.includes(answerId)
          ? prevSelection.filter((id) => id !== answerId)
          : [...prevSelection, answerId];
        return { ...prevSelected, [questionId]: newSelection };
      } else {
        return { ...prevSelected, [questionId]: [answerId] };
      }
    });
  };

  const handleConfirm = () => {
    setHasConfirmed(true);
  };

  const renderQuestion = (question) => {
    return (
      <div className="selection-container">
        <p>{question.desc_es}</p>
        {question.options.map((option) => (
          <button
            key={option.id}
            className="cardMapped"
            onClick={() =>
              handleSelectAnswer(question.id, option.id, question.type)
            }
          >
            <label className="input_card_mapped">
              <input
                type={
                  question.type === 'MULTIPLE_CHOICE' ? 'checkbox' : 'radio'
                }
                name={`question-${question.id}`}
                value={option.id}
                checked={
                  selectedAnswers[question.id]?.includes(option.id) ?? false
                }
                onChange={() =>
                  handleSelectAnswer(question.id, option.id, question.type)
                }
                className="input_card_mapped"
              />
              <div
                className="card_mapped_content"
                style={{ cursor: 'pointer' }}
              >
                <span className="title_card_mapped">{option.name_es}</span>
                {option.desc_es && (
                  <span className="subtitle_card_mapped">{option.desc_es}</span>
                )}
              </div>
            </label>
          </button>
        ))}
      </div>
    );
  };

  return (
    <div style={fullScreenStyles}>
      <img src={BlueLogo} alt="BlueLogo" style={logoStyle} />
      <Container
        fluid
        className="h-100 d-flex align-items-center justify-content-between flex-column"
      >
        <button style={buttonStyle} onClick={back}>
          <img src={BackBlue} alt="back" style={{ width: '20px' }} />
        </button>
        {hasConfirmed ? (
          <Row className="d-fleX justify-content-center">
            <Col xs={10}>
              <StepperComponent
                currentStep={currentStage + 1}
                borderless
                isSixthStep
                steps={quizData?.stages[currentStage]?.questions.length || 0}
                title={quizData?.stages[currentStage]?.name_es || ''}
              />
            </Col>
            <Col xs={12} className="gap-after">
              <h2>{quizData?.stages[currentStage]?.name_es}</h2>
            </Col>
            {quizData &&
              quizData?.stages[currentStage]?.questions.length > 0 &&
              renderQuestion(
                quizData?.stages[currentStage]?.questions[currentQuestion]
              )}
            <Col xs={12} className="gap-after">
              <SliderComponent
                currentNumber={currentQuestion + 1}
                setNumber={handleNextQuestion}
                maxValue={quizData?.stages[currentStage]?.questions.length || 0}
                disabled={
                  !selectedAnswers[
                    quizData?.stages[currentStage]?.questions[currentQuestion]
                      ?.id
                  ]?.length
                }
              />
            </Col>
            <button onClick={handlePreviousQuestion} style={linkStyle}>
              Volver
            </button>
          </Row>
        ) : (
          <ProfilePersSixRenderer handleConfirm={handleConfirm} />
        )}
      </Container>
    </div>
  );
};

export default ProfilePersSix;
