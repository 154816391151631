import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { RegisterDataContext } from '../contexts/RegisterDataContext';
import { toast } from 'react-toastify';

const apiBaseUrl = 'https://api-stg.empleosmarketingdigital.com/v1/quiz';

export const useCulturalQuizApi = () => {
    const { userData } = useContext(UserContext);
    const [registerData] = useContext(RegisterDataContext);

    const responseData =  {
    "stages": [
      [
        {
          "id": 1,
          "order": 1,
          "name_es": "En un entorno laboral",
          "questions": [
            {
              "id": 1,
              "order": 1,
              "type": "MULTIPLE_CHOICE",
              "amount": 3,
              "desc_es": "Selecciona tres valores que te definen",
              "options": [
                {
                  "id": 1,
                  "question_id": 1,
                  "name_es": "Responsabilidad",
                  "desc_es": "Cumplir tareas y compromisos, asumiendo las consecuencias propias."
                },
                {
                  "id": 2,
                  "question_id": 1,
                  "name_es": "Integridad",
                  "desc_es": "Honestidad, transparencia y ética."
                },
                {
                  "id": 3,
                  "question_id": 1,
                  "name_es": "Colaboración",
                  "desc_es": "Trabajo en equipo, comunicación y respeto."
                },
                {
                  "id": 4,
                  "question_id": 1,
                  "name_es": "Innovación",
                  "desc_es": "Buscar ideas y soluciones nuevas."
                },
                {
                  "id": 5,
                  "question_id": 1,
                  "name_es": "Orientación al cliente",
                  "desc_es": "Enfocarse en las necesidades del cliente."
                },
                {
                  "id": 6,
                  "question_id": 1,
                  "name_es": "Diversidad e inclusión",
                  "desc_es": "Valorar la diversidad de pensamientos, culturas y experiencias, y promover la inclusión en todas las áreas de la empresa."
                }
              ]
            },
            {
              "id": 5,
              "order": 1,
              "type": "ORDER",
              "amount": null,
              "desc_es": "¿Qué crees que es más importante en la resolución de conflictos?",
              "options": [
                {
                  "id": 21,
                  "question_id": 5,
                  "name_es": null,
                  "desc_es": "Ganar la discusión y demostrar que tengo razón."
                },
                {
                  "id": 22,
                  "question_id": 5,
                  "name_es": null,
                  "desc_es": "Mantener la armonía y encontrar una solución beneficiosa para ambas partes."
                },
                {
                  "id": 23,
                  "question_id": 5,
                  "name_es": null,
                  "desc_es": "Aprender de la situación para evitar futuros conflictos."
                }
              ]
            }
          ]
        },
        {
          "id": 2,
          "order": 2,
          "name_es": "Resolución de conflictos",
          "questions": [
            {
              "id": 2,
              "order": 2,
              "type": "SINGLE_SELECTION",
              "amount": null,
              "desc_es": "¿Qué es lo más importante para ti?",
              "options": [
                {
                  "id": 7,
                  "question_id": 2,
                  "name_es": null,
                  "desc_es": "La competitividad y el logro de objetivos."
                },
                {
                  "id": 8,
                  "question_id": 2,
                  "name_es": null,
                  "desc_es": "El respeto y la colaboración entre compañeros."
                },
                {
                  "id": 9,
                  "question_id": 2,
                  "name_es": null,
                  "desc_es": "La innovación y la creatividad en las soluciones."
                },
                {
                  "id": 10,
                  "question_id": 2,
                  "name_es": null,
                  "desc_es": "El crecimiento profesional y las oportunidades de desarrollo."
                },
                {
                  "id": 11,
                  "question_id": 2,
                  "name_es": null,
                  "desc_es": "El ambiente de trabajo positivo y motivador."
                },
                {
                  "id": 12,
                  "question_id": 2,
                  "name_es": null,
                  "desc_es": "La comunicación abierta y transparente."
                }
              ]
            },
            {
              "id": 6,
              "order": 2,
              "type": "ORDER",
              "amount": null,
              "desc_es": "¿Cómo reaccionarías si te enfrentas a una crítica constructiva sobre tu trabajo?",
              "options": [
                {
                  "id": 24,
                  "question_id": 6,
                  "name_es": null,
                  "desc_es": "Me sentiría ofendido y justificaría mi trabajo."
                },
                {
                  "id": 25,
                  "question_id": 6,
                  "name_es": null,
                  "desc_es": "Escucharía atentamente y trataría de mejorar en base a la crítica."
                },
                {
                  "id": 26,
                  "question_id": 6,
                  "name_es": null,
                  "desc_es": "Agradecería la retroalimentación y buscaría oportunidades para crecer profesionalmente."
                }
              ]
            }
          ]
        },
        {
          "id": 3,
          "order": 3,
          "name_es": "Motivación y trabajo en equipo",
          "questions": [
            {
              "id": 3,
              "order": 3,
              "type": "SINGLE_SELECTION",
              "amount": null,
              "desc_es": "¿Qué significa para ti?",
              "options": [
                {
                  "id": 13,
                  "question_id": 3,
                  "name_es": null,
                  "desc_es": "Cumplir con mis tareas asignadas sin importar las circunstancias."
                },
                {
                  "id": 14,
                  "question_id": 3,
                  "name_es": null,
                  "desc_es": "Ser proactivo en la resolución de problemas y asumir las consecuencias de mis acciones."
                },
                {
                  "id": 15,
                  "question_id": 3,
                  "name_es": null,
                  "desc_es": "Trabajar en equipo para lograr los objetivos de la empre."
                },
                {
                  "id": 16,
                  "question_id": 3,
                  "name_es": null,
                  "desc_es": "Tomar la iniciativa y anticiparme a las necesidades del trabajo para garantizar un buen desempeño."
                },
                {
                  "id": 17,
                  "question_id": 3,
                  "name_es": null,
                  "desc_es": "Asumir la responsabilidad de mis errores y aprender de ellos para mejorar en el futuro."
                }
              ]
            }
          ]
        },
        {
          "id": 4,
          "order": 4,
          "name_es": "Comunicación y liderazgo",
          "questions": [
            {
              "id": 4,
              "order": 4,
              "type": "ORDER",
              "amount": null,
              "desc_es": "Situación: Mi compañero no entrega a tiempo su material, lo que impide que yo pueda avanzar con  mi entrega, ya que mi trabajo depende del suyo.",
              "options": [
                {
                  "id": 18,
                  "question_id": 4,
                  "name_es": null,
                  "desc_es": "Dialogar con mi compañero para entender y, si es posible, ayudarlo en su tarea."
                },
                {
                  "id": 19,
                  "question_id": 4,
                  "name_es": null,
                  "desc_es": "Encontrar medidas temporales para seguir adelante con mi trabajo."
                },
                {
                  "id": 20,
                  "question_id": 4,
                  "name_es": null,
                  "desc_es": "Convocar al equipo para un diálogo en busca de soluciones comunes."
                }
              ]
            }
          ]
        }
      ]
    ]
  }
    // Obtener el cuestionario completo
    const fetchFullQuiz = async (language = 'es') => {
        try {
            const response = await axios.get(`${apiBaseUrl}/${language}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            return response.data;
        } catch (error) {
            toast.error('Error fetching full quiz:', error);
            return null;
        }
    };

    // Crear una etapa del cuestionario (Admins)
    const createQuizStage = async (stageData) => {
        try {
            const response = await axios.post(`${apiBaseUrl}/stages`, stageData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            console.log('Quiz stage created:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error creating quiz stage:', error);
            return null;
        }
    };

    // Actualizar una etapa del cuestionario (Admins)
    const updateQuizStage = async (quizStageId, stageData) => {
        try {
            const response = await axios.put(`${apiBaseUrl}/stages/${quizStageId}`, stageData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            console.log('Quiz stage updated:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error updating quiz stage:', error);
            return null;
        }
    };

    // Eliminar una etapa del cuestionario (Admins)
    const deleteQuizStage = async (quizStageId) => {
        try {
            await axios.delete(`${apiBaseUrl}/stages/${quizStageId}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            console.log('Quiz stage deleted');
        } catch (error) {
            toast.error('Error deleting quiz stage:', error);
        }
    };

    // Crear una pregunta del cuestionario (Admins)
    const createQuizQuestion = async (questionData) => {
        try {
            const response = await axios.post(`${apiBaseUrl}/questions`, questionData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            console.log('Quiz question created:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error creating quiz question:', error);
            return null;
        }
    };

    // Actualizar una pregunta del cuestionario (Admins)
    const updateQuizQuestion = async (quizQuestionId, questionData) => {
        try {
            const response = await axios.put(`${apiBaseUrl}/questions/${quizQuestionId}`, questionData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            console.log('Quiz question updated:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error updating quiz question:', error);
            return null;
        }
    };

    // Eliminar una pregunta del cuestionario (Admins)
    const deleteQuizQuestion = async (quizQuestionId) => {
        try {
            await axios.delete(`${apiBaseUrl}/questions/${quizQuestionId}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            console.log('Quiz question deleted');
        } catch (error) {
            toast.error('Error deleting quiz question:', error);
        }
    };

    // Crear una opción de pregunta del cuestionario (Admins)
    const createQuizOption = async (optionData) => {
        try {
            const response = await axios.post(`${apiBaseUrl}/options`, optionData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            console.log('Quiz option created:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error creating quiz option:', error);
            return null;
        }
    };

    // Actualizar una opción de pregunta del cuestionario (Admins)
    const updateQuizOption = async (quizOptionId, optionData) => {
        try {
            const response = await axios.put(`${apiBaseUrl}/options/${quizOptionId}`, optionData, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            console.log('Quiz option updated:', response.data);
            return response.data;
        } catch (error) {
            toast.error('Error updating quiz option:', error);
            return null;
        }
    };

    // Eliminar una opción de pregunta del cuestionario (Admins)
    const deleteQuizOption = async (quizOptionId) => {
        try {
            await axios.delete(`${apiBaseUrl}/options/${quizOptionId}`, {
                headers: {
                    'Authorization': `Bearer ${userData.token || registerData.token}`
                }
            });
            console.log('Quiz option deleted');
        } catch (error) {
            toast.error('Error deleting quiz option:', error);
        }
    };


    return {
        fetchFullQuiz,
        createQuizStage,
        updateQuizStage,
        deleteQuizStage,
        createQuizQuestion,
        updateQuizQuestion,
        deleteQuizQuestion,
        createQuizOption,
        updateQuizOption,
        deleteQuizOption

    };
};